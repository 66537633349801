<template>
  <div class="pb-3">
    <div>
      <b-img
        v-bind="imagePreviewSettings"
        class="my-3 preview"
        :src="imageUrl"
        :blank="!imageUrl"
      />
      <span class="clickable cancel-image">
        <b-icon-x
          v-if="formItem.fileBytes"
          scale="1.5"
          variant="danger"
          @click="cancelImage"
        />
      </span>
    </div>
    <div>
      <b-form-file
        ref="input-file"
        v-model="file"
        accept="image/png, image/jpeg"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        style="max-width: 500px"
      ></b-form-file>
    </div>
    <div class="m-2">
      <span class="clickable" title="Clear the image." @click="removeImage"
        >Remove image</span
      >
    </div>
    <div>
      <small class="form-text text-muted ml-2"
        >Supported file types: png, jpeg, jpg, jfif, pjpeg, pjp (up to
        1MB)</small
      >
    </div>
  </div>
</template>

<script>
export default {
  /*global _*/
  props: ['image', 'customPreviewSettings', 'maxDimensions'],
  mounted() {
    this.imagePreviewSettings = _.assign(
      {},
      this.imagePreviewSettings,
      this.customPreviewSettings
    );
    this.formItem = _.cloneDeep(this.image);
  },
  data() {
    return {
      imagePreviewSettings: { blankColor: '#bebebe' },
      file: null,
      formItem: {
        fileName: null,
        fileBytes: null,
        fileUrl: '',
        originalFileUrl: '',
      },
      formItemDefault: {
        fileName: null,
        fileBytes: null,
      },
    };
  },
  computed: {
    imageUrl() {
      return this.formItem.fileUrl || this.formItem.originalFileUrl;
    },
  },
  watch: {
    image(newValue) {
      this.formItem = _.cloneDeep(newValue);
    },
    file(newValue) {
      const vm = this;
      let file = newValue;
      if (file) {
        let fileName = file.name;
        let maxDimensions = this.maxDimensions;
        if (!maxDimensions) {
          maxDimensions = {
            width: 320,
            height: 240,
          };
        }

        // let maxWidth = maxDimensions.width;
        // let maxHeight = maxDimensions.height;

        if (!file.type.match(/image.*/)) {
          vm.$bvModal.msgBoxOk(
            'File format not supported. Please upload an image file.',
            {
              okVariant: 'danger',
              centered: true,
            }
          );
          return;
        }

        if (file.type.match(/image\/gif/)) {
          vm.$bvModal.msgBoxOk('GIF image type is not supported.', {
            okVariant: 'danger',
            centered: true,
          });
          return;
        }

        let image = document.createElement('img');

        image.onload = () => {
          let width = image.width;
          let height = image.height;
          // let isTooLarge = false;

          if (width >= height && width > maxDimensions.width) {
            height *= maxDimensions.width / width;
            width = maxDimensions.width;
            // isTooLarge = true;
          } else if (height > maxDimensions.height) {
            width *= maxDimensions.height / height;
            height = maxDimensions.height;
            // isTooLarge = true;
          }

          let canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;

          let ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob((blob) => {
            let objectUrl = window.URL.createObjectURL(blob);
            vm.formItem.fileUrl = objectUrl;

            let reader = new FileReader();
            reader.onload = function () {
              let arrayBuffer = this.result;
              let array = new Uint8Array(arrayBuffer);
              let binaryString = array.reduce(function (data, byte) {
                return data + String.fromCharCode(byte);
              }, '');
              let fileBytes = btoa(binaryString);

              vm.formItem.fileName = fileName;
              vm.formItem.fileBytes = fileBytes;

              vm.$emit('update:image', vm.formItem);
            };

            reader.readAsArrayBuffer(blob);
          }, file.type);
        };

        image.src = URL.createObjectURL(file);
      }
    },
  },
  methods: {
    cancelImage() {
      this.formItem.fileUrl = '';
      this.file = null;
      this.formItem = _.assign({}, this.formItem, this.formItemDefault);
      this.$emit('update:image', this.formItem);
    },
    removeImage() {
      this.formItem.fileUrl = '';
      this.file = null;
      this.formItem = _.assign({}, this.formItem, this.formItemDefault);
      this.formItem.originalFileUrl = null;
      this.$emit('update:image', this.formItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  border: 1px solid #bebebe;
}

.cancel-image {
  position: absolute;
  top: 18px;
  margin-left: -22px;
}
</style>
